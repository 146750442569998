<template>
  <div class="sport">

    <header-sport-container name="Спорт"
                            v-bind:is-transition="false"></header-sport-container>

    <div id="sport_main_wrapper">

      <div id="sport_main">

        <div id="sport_main_content">

          <div id="sport_main_text">
            <transition name="content_photo_move" appear>
              <div>
                Страница находится в разработке
              </div>
            </transition>

          </div>

      </div>

      </div>

    </div>

  </div>
</template>

<script>
//import headerLitContainerMini from "@/components/headerLitContainerMini.vue";
//import headerSportContainerContainer from "@/components/headerTasksContainer.vue";
import headerSportContainer from "@/components/headerSportContainer.vue";

export default {
  name: "secret",
  components: {headerSportContainer},
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    secret()  {
      return this.$route.params.id;
    },
    /*litItems() {
      return this.$store.getters.getLitItems
    },
    nameID : function () {
      return this.litItems.find(item => item.itemID === this.litId).title
    },
    text : function () {
      return this.litItems.find(item => item.itemID === this.litId).text
    },
    photo : function () {
      return this.litItems.find(item => item.itemID === this.litId).photo
    },
    year : function () {
      return this.litItems.find(item => item.itemID === this.litId).year
    }

     */
  },
}
</script>

<style scoped>

.sport {
  width:                  100%;
  max-width:              100%;
  background-color:       #fdf5e6;
  margin-top:             var(--header-height);

}

#sport_main_wrapper {
  display:                grid;
  grid-template-columns:  1fr;
  max-width:              var(--width-wrapper-small);
  height:                 auto;
  min-height: 100vh;
  padding-left:           .5rem;
  padding-right:          .5rem;
  margin:                 1rem auto 1rem auto;
}

@media (min-width: 60rem) {
  #sport_main_wrapper {
    max-width:              var(--width-wrapper-middle);
  }
}
@media (min-width: 75rem) {
  #sport_main_wrapper {
    max-width:              var(--width-wrapper-large);
  }
}

#sport_main {
  display: 			          grid;
  grid-template-columns:  1fr;
  grid-gap:               1rem;
  max-width:              100%;
  min-width:              100%;
  margin-top:             2rem;
  margin-bottom:          2rem;
  justify-self:           left;
  grid-template-rows:     auto auto;
  font-weight:            400;
}

@media (min-width: 60rem) {
  #sport_main {
    grid-template-columns:  1fr;
    grid-template-rows:     minmax(495px, auto);
    justify-content:        left;
    align-content:          flex-start;
    max-width:              var(--width-wrapper-middle);
  }
}

@media (min-width: 75rem) {
  #sport_main {
    grid-template-columns:  1fr;
    grid-template-rows:     minmax(495px, auto);
    justify-content:        left;
    align-content:          flex-start;
    max-width:              var(--width-wrapper-large);
  }
}

#sport_main_text {
  color:                  #555;
  font-size:              var(--menu-item-font-size-20);
}

@media (min-width: 60rem) {

  #sport_main_text {
    font-size:              calc(1*var(--menu-item-font-size-25));
  }
}

.slideLineText-enter-active {
  transition: all 1s ease;
}
.slideLineText-leave-active {
}
.slideLineText-enter-from {
  transform: translateX(200px);
  opacity: 0;
}
.slideLineText-leave-to {
}

.content_photo_move-enter-active {
  transition: all 1s ease-out;
}

.content_photo_move-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.content_photo_move-enter-from,
.content_photo_move-leave-to {
  transform: translateX(22px);
  opacity: 0;
}

.content_name_move-enter-active {
  transition: all 1s ease-out;
}

.content_name_move-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.content_name_move-enter-from,
.content_name_move-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

</style>